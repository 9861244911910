.validation-table-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  height: 60%;
  margin-top: 5%;
}

.pred-cont,
.tag-cont {
  width: 60%;
  background-color: #f5f5f5;
}

.pred-inner-cont,
.tag-inner-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.validation-t {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-left: 5px;
}

.t-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.badge-disease {
  border: transparent;
  border-radius: 100%;
  background-color: #df6363;
  width: 15px;
  height: 15px;
}

.badge-healthy {
  border: transparent;
  border-radius: 100%;
  background-color: #82c64e;
  width: 15px;
  height: 15px;
}

.divider-tags {
  border-bottom: 0.1rem solid #999;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.particular-tag {
  width: 25%;
  padding: 2px;
}

.column-tags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
