.btns-table{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    padding-bottom: 10px;
}
.items-text-center{
    align-items: center;
    text-align: center;
}
.m-auto{
    margin: auto;
}
.w-mid{
    width: 50%;
}
.pl-08r{
    padding-left: 0.8em;
}
.ml-null{
    margin-left: 0;
}