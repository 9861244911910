.measurement-image-container{
    width: 50%;
    margin: auto;
    align-items: center;
    text-align: center;
}

.measurement-loading{
    color: #6f7e90;
    font-size: 28px;
}

