.profile-list{
    max-height: 100%;
    width: 100%;
    padding: 2rem;
    /* overflow: 'auto', */
}
.profile-indicator-title{
    font-weight: 600;
}
.profile-form{
    font-size: 1.1rem;
}
.img-cropper{
    position: relative;
    min-height: 400;
}
.update-avatar-card{
    height: 2rem;
    width: 2rem;
}
.no-display-inputforfile{
    display: none;
}