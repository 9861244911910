/*----------------------------------------------------------
  --------------------CUSTOM DATE FILTER-----------------------------
  -----------------------------------------------------------*/
/* .MuiInputBase-root {
  min-height: 70px;
} */

.select-icons{
  
}

.close-btn {
  all: unset;
  cursor: pointer;
}

.dateFilter {
  grid-row: 2/3;
  grid-column: 6/11;
  border-color: rgba(0, 0, 0, 0.23);
  border: solid;
  border-radius: 4px;
  border-width: 1px;
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  background-color: #f0f3f8;
  min-height: 50px;
  border-color: rgba(0, 0, 0, 0.23);
}

.dateFilter:hover {
  border-color: black;
}

.dateFilter:focus-visible {
  border-color: black;

  border-width: 4px;
}

.dateFilter p:first-child {
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  padding: 0;
}
.dateFilter .customDrop {
  padding: 0;
  height: 80%;
  background-color: #f0f3f8;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-top: 2%;
  border: solid;
  border-radius: 4px;
  border-width: 1px;

  border-color: rgba(0, 0, 0, 0.23);
}
.customDrop .fa-calendar-alt {
  color: #a4a4c2;
  font-size: calc((100vh + 100vw) / 150);
  margin-left: 5%;
}
.customDrop p {
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato;
  font-weight: 400;
  font-size: calc((100vh + 100vw) / 160);
  transform: translateX(calc(-100vw / 80));
}
.customDrop .fa-caret-down {
  color: #a4a4c2;
  font-size: 15px;
  margin-right: 5%;
  float: right;
}

/*------------------------------
  ----------drop card-------------
  ------------------------------*/

.dropCard {
  position: absolute;
  width: calc(100vw / 7.7);
  height: fit-content;
  margin-left: 4%;
  background-color: white;
  z-index: 150;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e6e8ed;
  margin-top: 2%;
}
.hide {
  display: none !important;
}
.changeCard {
  color: #6f7e90;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(164, 164, 194, 0.5);
  height: calc(100vh / 20);
}
.changeCard p {
  margin: 0;
  font-size: calc((100vh + 100vw) / 166.6);
}
.selectedCard {
  border-bottom: 4px solid #8ad754;
}
.selectedCard p {
  color: #8ad754;
}
.datePickerContainer {
  grid-column: 1/ 3;
  grid-row: 1/8;
}
.datePickerContainer div:first-child {
  height: fit-content;
}
.react-datepicker {
  width: 100%;
  height: fit-content;
  border: none !important;
}
.react-datepicker__navigation {
  line-height: calc(100vh / 34.8) !important;
  top: calc(100vh / 94) !important;
  height: calc(100vh / 94) !important;
  width: calc(100vw / 192) !important;
  display: flex !important;
  align-items: center !important;
  font-size: calc((100vw + 100vh) / 200) !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #a3e773 !important;
  outline: none;
}
.react-datepicker__navigation--next {
  border-left-color: #a3e773 !important;
  outline: none;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__week {
  display: flex;
  flex-direction: row;
  padding-left: 0.3rem;
}
.react-datepicker__day {
  padding: 0.5vh;
  text-align: center;
  color: #7e8191 !important;
  outline: none;
  padding-left: 0;
  padding-right: 0;
}
.react-datepicker__header {
  background-color: #fff !important;
}
.react-datepicker__current-month {
  border-bottom: 1px solid rgb(243, 243, 243);
  padding-bottom: 5px;
  color: #a4a4c2 !important;
}
.react-datepicker__header {
  border-bottom: 1px solid rgb(243, 243, 243) !important;
}
.react-datepicker__day {
  font-size: calc((100vh + 100vw) / 166);
  line-height: calc(100vh / 34.8) !important;
  width: calc(100vw / 71.1) !important;
}
.react-datepicker__current-month {
  font-size: calc((100vh + 100vw) / 166) !important;
}
.react-datepicker__day-name {
  color: #6e6e8a !important;
  font-size: calc((100vh + 100vw) / 166) !important;
  line-height: calc(100vh / 34.8) !important;
  width: calc(100vw / 71.1) !important;
}
.react-datepicker__day--selected {
  background-color: #a4a4c2 !important;
  color: white !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: #fff !important;
  background-color: #a4a4c2 !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: #fff !important;
  background-color: #bcbcc7 !important;
}
