.tags-measur {
  display: flex;
  flex-direction: row;
}

.tag,
.tag-disease,
.tag-healthy {
  border: 1px solid;
  border-radius: 20px;
  padding: 3px 6px 3px 6px;
  margin-right: 6px;
}

.tag {
  background-color: rgba(152, 152, 152, 0.2);
}

.tag-bold {
  font-weight: bolder;
  padding-inline: 8px;
}

.tag-disease {
  background-color: rgba(255, 0, 0, 0.2);
}

.tag-healthy {
  background-color: rgba(0, 255, 0, 0.2);
}