.accept-cancel-buttons-container, .cancel-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 3em;
    padding-bottom: 1em;
    min-width: 70%;
}
.csv-upload-input{
    display: none;
}
.cancel-button{
    display: flex;
    flex-direction: row;

}