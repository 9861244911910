.container {
  height: 100vh;
  width: 22rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 6;
  padding: 1 1;
  padding-top: 1rem;
  overflow: hidden;
  background: linear-gradient(rgb(106 124 161), rgb(59 75 109));
}
.logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-daiana {
    max-width: 80%;
    max-height: 100%;
}
.profilecard-container{
    width: 100%;
    margin-bottom: 1rem;
}
.languagetoggler-container{
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-bottom: 1rem;
}
.logout-container{
    background-color: #FFFFFF80;
    height: 1px;
    width: 95%;
    padding: 0 10px;
    margin-top: 1rem;
}