.bg-pbar {
  width: 100%;
  border-radius: 0.65rem;
  height: 28px;
}

.p-bar-healthy {
  background-color: rgba(0, 255, 0, 0.2);
}

.p-bar-disease {
  background-color: rgba(255, 0, 0, 0.2);
}

.p-bar-common {
  height: 28px;
  border-radius: 0.65rem;
  padding-left: 6px;
}

.p-bar-text {
  white-space: nowrap;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-healthy {
  color: #4b7c02;
}

.text-disease {
  color: #ba0909;
}
