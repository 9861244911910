.plant-details-title{
    text-align: center;
}
.transform-wrapper{
    height: 550px;
    width: 700;
}
.measurement-table-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 60%;
    margin-top: 5%;
}
