.no-records{
    width: 50%;
    margin: auto;
    align-items: center;
    text-align: center;
}
.right-download-btns{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
}