.plants-carousel-container{
    width: 80%;
    margin: auto;
}
.common-plants-title{
    text-align: center;
}
.plants-carousel-inner-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 60%;
    margin-top: 5%;
}