.wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -15px;
  top: -20px;
}

.badge {
  display: inline-block;
  min-width: 2em;
  /* em unit */
  padding: .3em;
  /* em unit */
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  background: red;
  color: #fefefe;
}

.relative {
    position: relative;
}