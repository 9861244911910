.inner-profilecard-container:hover {
  padding: 1.5rem;
  cursor: pointer;
}
.p-1r{
    padding: 1rem;
}
.circular-progress {
  height: 2rem;
  width: 2rem;
}
.profile-card-text-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.profile-card-text-inner-container {
  margin-left: 1rem;
}
.profile-card-greetings {
  font-family: 'Dosis', 'Helvetica';
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  color: #ffffee;
}
.profile-card-username {
  font-family: 'Dosis', 'Helvetica';
  font-size: 1.25rem;
  font-weight: 500;
  color: #1fca3c;
  text-align: center;
}
.profile-card-editIcon{
    color: #ffffee;
}

